import axios from '@/config/httpConfig'
// 获取form的基本信息
export function getFormInfo(data) {
    return axios.post('/form/getFormInfo', data)
}
// 获取form的PageConversation信息
export function getPageConversation(data) {
    return axios.post('/form/getPageConversation', data)
}
// 获取form的SubmissionsSource信息
export function getSubmissionsSource(data) {
    return axios.post('/form/getSubmissionsSource', data)
}