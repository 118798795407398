/*
 * @Author: your name
 * @Date: 2022-03-10 10:09:06
 * @LastEditTime: 2022-03-15 10:44:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\marketForm\detailForm\api.js
 */
import axios from '@/config/httpConfig'
// 获取form的基本信息
export function getFormInfo(data) {
    return axios.post('/form/getFormInfo', data)
}
//获取form的字段提交信息
export function getFormFieldSubmissions(data) {
    return axios.post('/form/getFormFieldSubmissions', data)
}
//获取form的选项列表字段提交信息
export function getFormFieldTypeSubmissions(data) {
    return axios.post('/form/getFormFieldTypeSubmissions', data)
}

//保存form信息
export function saveForm(data) {
    return axios.post('/form/saveForm', data)
}

// 获取form的潜在客户的字段设置
export function getFieldDefault(data) {
    return axios.post('/form/getFieldDefault', data)
}

// 获取form的市场活动成员的字段设置
export function getFieldShow(data) {
    return axios.post('/form/getFieldShow', data)
}

//获取form详细信息
export function getFormdetail(data) {
    return axios.post('/form/getFormDetail', data)
}

// 删除表单
export function deleteForm(data) {
    return axios.post('/form/deleteForm', data)
}