<template>
  <div class="perform">
    <div class="perhead">
      <div class="perdate">
        <div class="Daterange">
          {{ $t("label.chatter.dateRange") }}：
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <span>{{ Daterange }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(range, num) in Dateranges"
                :key="num"
                :command="range"
                >{{ range }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="datepicker"
          v-show="Daterange == `${$t('label.custom_time')}`"
        >
          <el-date-picker
            v-model="datepickervalue"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            :picker-options="pickerOptions"
            start-placeholder="YYYY-MM-DD"
            end-placeholder="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
      </div>
      <Analyzedate :analyzedate="analyze"></Analyzedate>
      <Analyechart
        :h1="title"
        :echarttitle="echarttitle"
        :echartstartdate="startdate"
        :echartenddate="enddate"
        :timeHorizon="timezon"
      ></Analyechart>
      <!-- Conversion pages 表格-->
      <div class="Conversionpages">
        <performList
          :title="$t('label.conversion_pages')"
          :startdate="startdate"
          :enddate="enddate"
          :tableAttr="tableAttr"
          :dataList="dataList"
        ></performList>
      </div>
      <!-- <div class="">
        <performList
          :title="'Submissions by source'"
          :startdate="startdate"
          :enddate="enddate"
          :tableAttr="subtableAttr"
          :dataList="subdataList"
        ></performList>
      </div> -->
    </div>
  </div>
</template>
<script>
import performList from "@/views/marketForm/detailForm/component/performList.vue";
import Analyzedate from "@/views/marketForm/components/Analyzedate.vue";
import Analyechart from "@/views/marketForm/components/Analyechart";
import * as marketChannel from "./api"; //api

export default {
  components: {
    performList,
    Analyzedate,
    Analyechart,
  },
  props: {
    formid: {
      //formid
      type: String,
      default: "",
    },
    customcreatedate:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      analyze: {
        title: this.$i18n.t("label.aggregated_formsdata"),
        startdate: "",
        enddate: "",
        metrics: [],
        name: "",
        id: "",
      },
      Daterange: this.$i18n.t("label.notification.last30days"),
      Dateranges: [
        this.$i18n.t("component.timeline.label.today"),
        this.$i18n.t("label.brief.thisweek"),
        this.$i18n.t("this.year"),
        this.$i18n.t("label.notification.last30days"),
        this.$i18n.t("label.custom_time"),
      ],
      datepickervalue: [],
      pickerOptions:{
        disabledDate(v){
                return v.getTime() > new Date().getTime();
              }
      },
      echarttitle: this.$i18n.t("label.session_date"),
      title: this.$i18n.t("label.total_views"),
      startdate: "", //开始时间
      enddate: "", //结束时间
      tableAttr: [
        //schemefieldType  类型
        {
          schemefieldName: this.$i18n.t("label.visiturl"),
          schemefieldType: "L",
          key: "visiturl",
        },
        {
          schemefieldName: this.$i18n.t("label.submissions"),
          schemefieldType: "L",
          key: "submissions",
        },
        {
          schemefieldName: this.$i18n.t("label.views"),
          schemefieldType: "L",
          key: "views",
        },
        {
          schemefieldName: this.$i18n.t("label.submission_rate"),
          schemefieldType: "L",
          key: "submissionRate",
        },
      ],
      dataList: [],
      subtableAttr: [
        //schemefieldType  类型
        {
          schemefieldName: "sitetype",
          schemefieldType: "L",
          key: "sitetype",
        },
        {
          schemefieldName: "submissions",
          schemefieldType: "L",
          key: "submissions",
        },
        {
          schemefieldName: "views",
          schemefieldType: "L",
          key: "views",
        },
        {
          schemefieldName: "submissionRate",
          schemefieldType: "L",
          key: "submissionRate",
        },
      ],
      subdataList: [],
      timezon: "", //echarts折线图的时间间隔
    };
  },
  watch: {
    datepickervalue() {
      this.startdate = this.datepickervalue[0];
      this.enddate = this.datepickervalue[1];
      this.analyze.startdate = this.datepickervalue[0];
      this.analyze.enddate = this.datepickervalue[1];
      let yearone = this.datepickervalue[0].split("-")[0];
      let monthone = this.datepickervalue[0].split("-")[1];
      let yeartwo = this.datepickervalue[1].split("-")[0];
      let monthtwo = this.datepickervalue[1].split("-")[1];
      if (yearone == yeartwo && monthone == monthtwo) {
        this.timezon = "everyday";
      } else {
        this.timezon = "everymonth";
      }
      this.getformdata();
      this.getConversion();
      this.getSubmissions();
    },
    // Daterange() {
    //   this.$store.state.creatform.perdaterange = this.Daterange;
    // },
  },
  methods: {
    handleCommand(command) {
      this.Daterange = command;
      this.getformdates(command);
      if (command !== this.$i18n.t("label.custom_time")) {
        //  获取数据
        this.getformdata();
        this.getConversion();
        this.getSubmissions();
      } else {
        this.datepickervalue = [this.customcreatedate,new Date().toLocaleDateString().replace(/\//g, "-")]
        let dataVal = new Date(this.customcreatedate)
        this.pickerOptions = {
            disabledDate(v){
              if(v.getTime()< dataVal.getTime()-24*60*60*1000){
                return true
              }
              if(v.getTime() > Date.now()){
                return true
              }
            }
          }
      }
    },
    //获取时间范围
    getformdates(time) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var nowDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      if (time == this.$i18n.t("component.timeline.label.today")) {
        this.startdate = nowDate;
        this.enddate = nowDate;
        this.timezon = "everyday";
      }
      let lastDate = "";
      if (time == this.$i18n.t("label.brief.thisweek")) {
        // 本周一的日期
          date.setDate(date.getDate() - date.getDay() + 1);
          var lastY = date.getFullYear();
          var lastM = date.getMonth() + 1;
          var lastD = date.getDate();
          let date =
            lastY +
            "-" +
            (lastM < 10 ? "0" + lastM : lastM) +
            "-" +
            (lastD < 10 ? "0" + lastD : lastD);
          this.startdate = date;
          this.enddate = nowDate;
          this.timezon = "everyday";
      }
      if (time == this.$i18n.t("label.notification.last30days")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 30);
        this.timezon = "everyday";
      }
      if (time == this.$i18n.t("this.year")) {
        this.startdate = year + "-01-01";
        this.enddate = nowDate;
        this.timezon = "everymonth";
      }
      if (time == this.$i18n.t("label.all_time")) {
        this.startdate = "";
        this.enddate = "";
        this.timezon = "everymonth";
      }
      if (lastDate) {
        let lastY = lastDate.getFullYear();
        let lastM = lastDate.getMonth() + 1;
        let lastD = lastDate.getDate();
        var lastdate =
          lastY +
          "-" +
          (lastM < 10 ? "0" + lastM : lastM) +
          "-" +
          (lastD < 10 ? "0" + lastD : lastD);
        this.startdate = lastdate;
        this.enddate = nowDate;
      }
      this.analyze.startdate = this.startdate;
      this.analyze.enddate = this.enddate;
    },
    //获取form基本信息
    getformdata() {
      let obj = {
        id: this.formid,
        startTime: this.startdate,
        endTime: this.enddate,
      };
      marketChannel.getFormInfo(obj).then((res) => {
        this.analyze.startdate = this.startdate;
        this.analyze.enddate = this.enddate;
        this.analyze.id = this.formid;
        this.analyze.name = res.data.subject;
        if (res.data.submission_rate == null || res.data.submission_rate == 0) {
          res.data.submission_rate = "0.00%";
        }
        this.analyze.metrics = [
          {
            dt: this.$i18n.t("label.submissions"),
            dd: res.data.submissions,
            key: "submissions",
          },
          {
            dt: this.$i18n.t("label.submission_rate"),
            dd: res.data.submission_rate,
            key: "submissionRate",
          },
          {
            dt: this.$i18n.t("label.views"),
            dd: res.data.views,
            key: "views",
          },
        ];
      });
    },
    // 获取form的PageConversation信息
    getConversion() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.startdate,
        endTime: this.enddate,
      };
      marketChannel.getPageConversation(obj).then((res) => {
        this.dataList = res.data.pageConversation;
      });
    },
    // 获取form的SubmissionsSource信息
    getSubmissions() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.startdate,
        endTime: this.enddate,
      };
      marketChannel.getSubmissionsSource(obj).then((res) => {
        this.subdataList = res.data.submissionsSource;
      });
    },
  },
  created() {
    this.getformdates(this.Daterange);
    this.getformdata();
    this.getConversion();
    this.getSubmissions();
    // this.datepickervalue = [this.customcreatedate,new Date().toLocaleDateString().replace(/\//g, "-")]
    // if (this.$store.state.creatform.perdaterange) {
    //   this.Daterange = this.$store.state.creatform.perdaterange;
    // }
  },
};
</script>
<style lang="scss" scoped>
.perform {
  width: 98.5%;
  margin: 10px auto;
  overflow: hidden;
  .perhead {
    width: 100%;
    .perdate {
      width: 100%;
      margin-bottom: 9px;
      display: flex;
      align-items: center;
      letter-spacing: 0;
    }
    .Daterange {
      float: left;
      margin-left: 16px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      width: 250px;
      .el-dropdown-link {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #2D6CFC;
        letter-spacing: 0;
      }
    }
    .datepicker {
      margin-left: 15px;
      ::v-deep {
        .el-range-editor.el-input__inner {
          padding: 0;
          cursor: pointer;
          width: 220px;
        }
        .el-input__inner {
          border: 0;
          height: 20px;
          line-height: 20px;
        }
        .el-range-input {
          cursor: pointer;
          color: #2D6CFC;
        }
        .el-date-editor .el-range__icon {
          line-height: 20px;
          margin: 0;
          width: 20px;
        }
        .el-date-editor .el-range-separator {
          line-height: 20px;
          padding: 0;
        }
        .el-date-editor .el-range-input {
          width: 90px;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 22px;
          width: 20px;
        }
      }
    }
    .Conversionpages {
      margin-bottom: 20px;
    }
  }
}
</style>
