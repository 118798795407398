<template>
  <div class="submission">
    <div class="shared">
      <div class="s1">
        <div class="s1left">
          <div class="Daterange">
            {{ $t("label.chatter.dateRange") }}：
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ timeop }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(range, num) in Dateranges"
                  :key="num"
                  :command="range"
                  >{{ range }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            class="datepicker"
            v-show="timeop == `${$t('label.custom_time')}`"
          >
            <el-date-picker
              v-model="datepickervalue"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              :picker-options="pickerOptions"
              start-placeholder="YYYY-MM-DD"
              end-placeholder="YYYY-MM-DD"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="s1right">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-setcou"></use>
          </svg>
          <span @click="dialogVisible = true">{{
            $t("label.manage_columns")
          }}</span>
        </div>
      </div>
      <div class="s2">
        <!-- 表格 -->
        <template>
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            :cell-style="{ padding: '0' }"
            :row-style="{ height: '64px' }"
            :header-cell-style="{
              background: '#FAFAF9',
              height: '40px',
              color: '#333',
            }"
            @cell-mouse-enter="mouseenter"
            @cell-mouse-leave="mouseleave"
          >
         
            <template v-for="(tit, titindex) in checktitle">
              <slot :item="titindex">
                <el-table-column v-if="tit.name == 'Leads'" min-width="180">
                  <template slot="header">
                    <el-popover
                      placement="top"
                      trigger="hover"
                      popper-class="my-tooltip table-head-tooltip"
                      :content="$t('label.tabpage.potentialcustomersz')"
                    >
                      <div slot="reference">
                        {{ $t("label.tabpage.potentialcustomersz") }}
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div class="contactext">
                      <div class="refe">
                        <el-popover
                          placement="top-start"
                          trigger="hover"
                          popper-class="my-tooltip table-head-tooltip"
                          :content="
                            scope.row.name
                              ? scope.row.name
                              : scope.row.email
                              ? scope.row.email
                              : ''
                          "
                        >
                          <p slot="reference" :style="{maxWidth:scope.row.buttonshow?'220px':'100%'}">
                            {{
                              scope.row.name
                                ? scope.row.name
                                : scope.row.email
                                ? scope.row.email
                                : ""
                            }}
                          </p>
                        </el-popover>
                      </div>
                      <!-- 查看提交 -->
                      <el-button
                        @click="viewsub(scope.row)"
                        v-show="scope.row.buttonshow"
                        >{{ $t("c843") }}</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="tit.name !== 'Leads'"
                  :show-overflow-tooltip="true"
                >
                  <template slot="header">
                    <el-popover
                      placement="top"
                      trigger="hover"
                      popper-class="my-tooltip table-head-tooltip"
                      :content="tit.name"
                    >
                      <div
                        class="tableHeadField"
                        slot="reference"
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ tit.name }}
                      </div>
                    </el-popover>
                  </template>

                  <template slot-scope="scope">
                    <div
                      v-if="tit.key == 'Conversion page'"
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ scope.row.conversionpage }}
                    </div>
                    <div
                      v-if="tit.key == 'Submitted on'"
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ scope.row.submittedon }}
                    </div>
                    <div
                      v-if="
                        tit.key !== 'Conversion page' &&
                        tit.key !== 'Submitted on'
                      "
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ scope.row[tit.key] }}
                    </div>
                  </template>
                </el-table-column>
              </slot>
            </template>
          </el-table>
        </template>
      </div>
      <el-drawer
        :title="$t('label.submission_detail')"
        :visible.sync="sharepage"
      >
        <p
          style="width: 100%; height: 1px; background-color: #dedcda; margin: 0"
        ></p>
        <div class="pdiv">
          <div class="firstp">
            {{
              viewsubdata.name
                ? viewsubdata.name
                : viewsubdata.email
                ? viewsubdata.email
                : ""
            }}
          </div>
          <div class="secondp">
            {{ $t("label.submitted") }}{{ viewsubdata.submittedon }}
          </div>
          <!-- <el-button size="mini">Contact Record</el-button> -->
          <ul>
            <li v-for="(item, index) in lis" :key="index" class="viewslis">
              <p>{{ item.name }}</p>
              <h6 v-if="item.key == 'Conversion page'">
                {{ viewsubdata.conversionpage }}
              </h6>
              <h6 v-if="item.key == 'Submitted on'">
                {{ viewsubdata.submittedon }}
              </h6>
              <h6
                v-if="
                  item.key !== 'Conversion page' && item.key !== 'Submitted on'
                "
              >
                {{ viewsubdata[item.key] }}
              </h6>
            </li>
          </ul>
        </div>

        <hr
          style="
            margin: 20px 0;
            background-color: #dedcda;
            border: 0;
            height: 1px;
          "
        />
        <el-button class="footerbutton" @click="gotocontact(viewsubdata.leadid)"
          >{{ $t("label.view_contact") }}
          <svg class="icon" aria-hidden="true">
            <use href="#icon-share006dcc"></use>
          </svg>
        </el-button>
      </el-drawer>
      <div class="dialogg">
        <el-dialog
          :title="$t('label.edit_columns')"
          :visible.sync="dialogVisible"
          width="627px"
        >
          <div class="diaup">
            <span>{{ $t("label.select_columns") }}</span>
          </div>
          <!-- 必填字段筛选 -->
          <div class="diadown">
            <div class="dleft">
              <span>{{ $t("label.chatter.default") }}</span>
              <div class="defaultspanheader">
                <div class="" v-for="(item, index) in defaultList" :key="index">
                  <el-checkbox v-model="item.checked">{{
                    item.name
                  }}</el-checkbox>
                </div>
              </div>
              <span>{{ $t("label.form_fields") }}</span>
              <div class="formspan">
                <div class="" v-for="(item, index) in fieldsList" :key="index">
                  <el-checkbox v-model="item.checked">{{
                    item.name
                  }}</el-checkbox>
                </div>
                <br />
              </div>
            </div>
            <div class="dright">
              <span>{{ $t("label.selectedline") }}({{ number }})</span>
              <div class="contacts">
                {{ $t("label_tabpage_potentialcustomersz") }}
              </div>
              <vuedraggable v-model="allSelectTip">
                <div v-for="(item, index) in allSelectTip" :key="index">
                  <div class="other" v-if="item.checked">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-dragDrop"></use>
                    </svg>
                    {{ item.name
                    }}<i @click="closeTip(item)" class="el-icon-close"></i>
                  </div>
                </div>
              </vuedraggable>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
            <el-button type="primary" @click="selectitle">{{
              $t("component.telerecord.button.save")
            }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import * as marketChannel from "./api";//api

export default {
  components: {
    vuedraggable
  },
  props:{
    customcreatedate:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      allSelectTip: [],//选择的字段
      allSelectList: [],
      defaultList: [{ name: this.$i18n.t('label.conversion_pages'), checked: true, key: 'Conversion page' }, { name: this.$i18n.t('label.submitted_on'), checked: true, key: 'Submitted on' }],//默认字段
      fieldsList: [],//创建form时的必填字段
      sharepage: false,
      timeop: this.$i18n.t("label.notification.last30days"),
      // Conversion: "",
      // Submitted: "",
      // Email: "",
      // Firstname: "",
      // Lastname: "",
      // Company: "",
      // Country: "",
      // search: "",
      dialogVisible: false,

      tableData: [
      ],
      Dateranges: [
        this.$i18n.t('component.timeline.label.today'),
        this.$i18n.t("label.brief.thisweek"),
        this.$i18n.t("this.year"),
        this.$i18n.t("label.notification.last30days"),
        this.$i18n.t("label.custom_time")
      ],
      datepickervalue: [],
      pickerOptions:{
        disabledDate(v){
                return v.getTime() > new Date().getTime();
              }
      },
      number: null,
      checktitle: [],//选中的表头
      startdate: '',
      enddate: '',
      viewsubdata: {},
      lis: [],
    };
  },
  created() {
    this.getformdates(this.timeop);
    this.getformdata();
    // if (this.$store.state.creatform.subdaterange) {
    //   this.timeop = this.$store.state.creatform.subdaterange
    // }
  },
  mounted() {

  },
  watch: {
    allSelectList: {
      deep: true,
      handler() {
        let objJson = JSON.stringify(this.allSelectList)
        this.allSelectTip = JSON.parse(objJson)
        let num = 1;
        for (var i = 0; i < this.allSelectTip.length; i++) {
          if (this.allSelectTip[i].checked == true) {
            num++
          }
        }
        this.number = num;

        // this.select()


      }
    },
    datepickervalue() {
      this.startdate = this.datepickervalue[0];
      this.enddate = this.datepickervalue[1];
      this.getformdata();
    },
    // timeop() {
    //   this.$store.state.creatform.subdaterange = this.timeop
    // }
  },
  methods: {
    closeTip(item) {
      this.allSelectList.forEach(list => {
        if (list.name === item.name) {
          list.checked = false
        }
      })
    },
    //跳潜在客户详情
    gotocontact(id) {
      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
      this.sharepage = false;
    },
    
    handleCommand(command) {
      this.timeop = command;
      this.getformdates(command);
      this.currentPage = 1;
      if (command !== this.$i18n.t("label.custom_time")) {
        this.getformdata();
      } else {
        this.datepickervalue = [this.customcreatedate,new Date().toLocaleDateString().replace(/\//g, "-")]
        let dataVal = new Date(this.customcreatedate)
        this.pickerOptions = {
            disabledDate(v){
              if(v.getTime()< dataVal.getTime()-24*60*60*1000){
                return true
              }
              if(v.getTime() > Date.now()){
                return true
              }
            }
          }
      }
    },
    //获取时间范围
    getformdates(time) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var nowDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
      if (time == this.$i18n.t('component.timeline.label.today')) {
        this.startdate = nowDate;
        this.enddate = nowDate;
      }
      let lastDate = '';
      if (time == this.$i18n.t("label.brief.thisweek")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 7);
      }
      if (time == this.$i18n.t("label.notification.last30days")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 30);
      }
      if (time == this.$i18n.t("this.year")) {
        this.startdate = year + "-01-01";
        this.enddate = nowDate;
      }
      if (time == this.$i18n.t("label.all_time")) {
        this.startdate = '';
        this.enddate = '';
      }
      if (lastDate) {
        var lastY = lastDate.getFullYear();
        var lastM = lastDate.getMonth() + 1;
        var lastD = lastDate.getDate();
        var lastdate = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD);
        this.startdate = lastdate;
        this.enddate = nowDate;
      }
    },
    mouseenter(row) {
      row.buttonshow = true;
    },
    mouseleave(row) {
      row.buttonshow = false;
    },
    // eslint-disable-next-line no-unused-vars
    cellstyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return "font-weight:bold;color:#2D6CFC";
      } else {
        return "color:#2D6CFC";
      }
    },
    // 获取数据
    getformdata() {
      let params = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.startdate,
        endTime: this.enddate,
      };
      marketChannel.getFormSubmissions(params)
        .then((res) => {
          let condata = res.data.activityFields;
          let fildarr = [];

          for (var i = 0; i < condata.length; i++) {
            let fildobj = {};
            fildobj.name = condata[i].labelName;
            fildobj.key = condata[i].fieldname;
            fildobj.checked = true;
            fildarr.push(fildobj)
          }
          this.fieldsList = fildarr;
          this.allSelectList = this.defaultList.concat(this.fieldsList);
          let objJson = JSON.stringify(this.allSelectList);
          this.allSelectTip = JSON.parse(objJson);
          if (this.checktitle.length == 0) {
            for (let i = 0; i < this.allSelectTip.length; i++) {
              if (this.allSelectTip[i].checked == true) {
                this.checktitle.push(this.allSelectTip[i])
              }
            }
            this.checktitle.unshift({ name: 'Leads', checked: true })
          }

          for (let i = 0; i < res.data.submissionsList.length; i++) {
            res.data.submissionsList[i].buttonshow = false;
          }
          this.tableData = res.data.submissionsList;
        })
        .catch(() => {
          return false
        })
    },
    //筛选表头
    selectitle() {
      this.dialogVisible = false;
      this.select();

    },
    select() {

      let checkli = [];
      this.checktitle = []
      for (var i = 0; i < this.allSelectList.length; i++) {
        if (this.allSelectList[i].checked == true) {
          checkli.push(this.allSelectList[i])
        }
      }
      // this.checktitle = [...checkli]
      checkli.unshift({ name: 'Leads', checked: true });
      this.$nextTick(() => {
        this.checktitle = checkli
      })
    },
    viewsub(item) {

      this.lis = [...this.checktitle]
      this.lis.splice(0, 1);
      this.viewsubdata = item;
      this.sharepage = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  margin-left: 4px;
}
::v-deep .el-table__row:hover {
  background: #f5f8fa;
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
::v-deep .batchtable {
  margin-top: 5px;
  th {
    border-right: none;
  }
}
::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}
::v-deep .el-dialog_bady {
  overflow: auto;
  padding: 26px;
  font-size: 12px !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #2D6CFC;
}
.el-icon-arrow-down {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #2D6CFC;
  letter-spacing: 0;
}
.submission {
  .shared {
    width: 98.5%;
    // height: 300px;
    margin: 10px auto;
    letter-spacing: 0;
    .s1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;
      .s1left {
        .Daterange {
          float: left;
          margin-left: 16px;
          width:250px;
        }
        .datepicker {
          float: left;
          margin-left: 15px;
          ::v-deep {
            .el-range-editor.el-input__inner {
              padding: 0;
              cursor: pointer;
              width: 220px;
            }
            .el-input__inner {
              border: 0;
              height: 20px;
              line-height: 20px;
            }
            .el-range-input {
              cursor: pointer;
              color: #2D6CFC;
            }
            .el-date-editor .el-range__icon {
              line-height: 20px;
              margin: 0;
              width: 20px;
            }
            .el-date-editor .el-range-separator {
              line-height: 20px;
              padding: 0;
            }
            .el-date-editor .el-range-input {
              width: 90px;
            }
            .el-date-editor .el-range__close-icon {
              line-height: 22px;
              width: 20px;
            }
          }
        }
      }
      .s1right {
        span {
          cursor: pointer;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #2D6CFC;
          letter-spacing: 0;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
    .s2 {
      background: #ffffff;
      border: 1px solid #dedcda;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #2D6CFC;
      letter-spacing: 0;
      ::v-deep .el-table {
        img {
          cursor: pointer;
        }
        ::v-deep .el-table__row {
          box-sizing: border-box;
        }
        tr {
          box-sizing: border-box;
        }
      }
      .contactext {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          // max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 8px;
        }
        .refe {
          display: flex;
          align-items: center;
          vertical-align: middle;
          img {
            vertical-align: middle;
          }
        }
      }
      button {
        color: #2D6CFC;
        outline: none;
        height: 30px;
        width: 132px;
        padding: 0;
        line-height:0;
      }
      button:hover {
        background-color: #fff;
        color: #2D6CFC;
      }
    }
    .dialogg {
      .diaup {
        span {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #080707;
          letter-spacing: 0;
        }
      }
      .diadown {
        margin-top: 20px;
        display: flex;
        .dleft {
          border-right: 1px solid #dedcda;
          width: 50%;
          height: 300px;
          .defaultspanheader,
          .formspan {
            .el-checkbox {
              ::v-deep .is-checked {
                color: red !important;
              }
            }

            margin-bottom: 10px;
            .el-checkbox {
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #080707;
              letter-spacing: 0;
            }
          }
          span:nth-child(1),
          span:nth-child(5) {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #080707;
            letter-spacing: 0;
            display: inline-block;
            margin-bottom: 10px;
          }
          span:nth-child(3) {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #080707;
            letter-spacing: 0;
            display: inline-block;
            margin: 10px 0;
          }

          ::v-deep .el-input__inner {
            width: 262px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dedcda;
            border-radius: 3px;
            text-align: left;
          }
        }
        .dright {
          margin-left: 13px;
          height: 300px;
          overflow: auto;
          overflow-x: hidden;
          .contacts {
            width: 247px;
            height: 40px;
            margin-right: 4px;
            background: #ffffff;
            border: 1px solid #dedcda;
            border-radius: 3px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            padding: 8px 0 0 24px;
            margin-bottom: 10px;
          }
          .other {
            cursor: pointer;
            width: 247px;
            height: 40px;
            margin-right: 4px;
            background: #f8fcff;
            border: 1px solid #dedcda;
            border-radius: 3px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            padding: 8px 0 0 10px;
            margin-bottom: 10px;
            position: relative;
            img {
              margin-right: 10px;
            }
            i {
              position: absolute;
              top: 12px;
              right: 10px;
            }
          }
          span:nth-child(1) {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #080707;
            letter-spacing: 0;
            display: inline-block;
            margin-bottom: 10px;
          }
        }
      }
    }
    .el-drawer {
      .footerbutton {
        margin-bottom: 20px;
        margin-left: 40px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #2D6CFC;
        letter-spacing: 0;
        text-align: center;
      }
      .pdiv {
        margin-top: 20px;
        margin-left: 40px;
        button {
          margin-bottom: 14px;
        }
        .firstp {
          font-family: MicrosoftYaHei-Bold;
          font-weight: bold;
          font-size: 20px;
          color: #3e3c3c;
          letter-spacing: 0;
          margin-bottom: 14px;
        }
        .secondp {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #3e3c3c;
          letter-spacing: 0;
          margin-bottom: 14px;
        }
        p:nth-child(odd) {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #888888;
          letter-spacing: 0;
          margin-bottom: 0;
        }
        p:nth-child(even) {
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #080707;
          letter-spacing: 0;
        }
      }
    }
  }
  ::v-deep .el-drawer__header {
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    font-size: 18px;
    color: #3e3c3c;
    letter-spacing: 0;
    margin-left: 20px;
  }
}
.viewslis {
  margin-top: 20px;
  width: 100%;
  p {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #888888;
    letter-spacing: 0;
    line-height: 30px;
  }
  h6 {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    line-height: 30px;
  }
}
</style>
