<template>
  <div class="top">
    <div class="analyzeBox">
      <!--        头部-->
      <div class="tapHeader">
        <div class="Daterange">
          {{ $t("label.chatter.dateRange") }}：
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <span>{{ Daterange }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(range, num) in Dateranges"
                :key="num"
                :command="range"
                >{{ range }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="datepicker"
          v-show="Daterange == `${$t('label.custom_time')}`"
        >
          <el-date-picker
            v-model="datepickervalue"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            :picker-options="pickerOptions"
            start-placeholder="YYYY-MM-DD"
            end-placeholder="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
      </div>
      <!--        横向柱状图-->
      <div class="myChart">
        <div class="chartHeader">
          <div class="chatTitle">{{ $t("label.overall_responses") }}</div>
          <div class="buttoonWrapper">
            <el-button
              :type="fields == 0 ? 'primary' : ''"
              size="medium"
              style="border-radius: 3px 0px 0px 3px"
              @click="choosefields(0)"
              >{{ $t("label.see_allfields") }}</el-button
            >
            <el-button
              :type="fields == 1 ? 'primary' : ''"
              size="medium"
              style="border-radius: 0px 3px 3px 0px"
              @click="choosefields(1)"
              >{{ $t("label.optional_fields") }}</el-button
            >
          </div>
        </div>
        <v-chart
          autoresize
          :options="zhuzhuanghengchartOptions"
          v-if="echartquired.length > 0"
          class="vChart"
        ></v-chart>
        <div v-if="echartquired.length == 0" class="echartnodata">
          {{ $t("vue_label_SMS_nodata") }}
        </div>
        <div style="text-align: center">
          <el-pagination
            :page-size="pagesize"
            :current-page="pagecount"
            layout="prev, pager, next"
            :total="allpage"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="emailBox">
      <show-message
        :mainName="'Email'"
        :tips="'* required field'"
        :subtitle="'ANSWER'"
        :content="'923929@qq.com'"
      ></show-message>
    </div> -->
    <!-- <div class="fullNameBox">
      <show-message
        :mainName="'FulI name'"
        :tips="''"
        :subtitle="'ANSWER'"
        :content="'SHAO YUE'"
      ></show-message>
    </div>
    <div class="clear"></div>
    <div class="comNameBox">
      <show-message
        :mainName="'Company name'"
        :tips="'* required field'"
        :subtitle="'ANSWER'"
        :content="'ANSWER'"
      ></show-message>
    </div>
    <div class="phoneBox">
      <show-message
        :mainName="'Phone number'"
        :tips="''"
        :subtitle="'ANSWER'"
        :content="'+86 13321185636'"
      ></show-message>
    </div>
    <div class="clear"></div>
    <div class="countyBox">
      <show-message
        :mainName="'Country/Region'"
        :tips="''"
        :subtitle="'ANSWER'"
        :content="'美国'"
      ></show-message>
    </div>
    <email-icon class="emailIcon"></email-icon> -->
    <!-- 图表和圆形图切换 -->
    <div class="iconselect">
      <div class="empList" v-for="(item, index) in tabledata" :key="index">
        <div class="chartHeader pd-20">
          <div class="chatTitle">{{ item.label }}</div>
          <div class="buttoonWrapper">
            <el-button
              :type="item.switchFlg == 0 ? 'primary' : ''"
              size="medium"
              style="border-radius: 3px 0px 0px 3px"
              @click="item.switchFlg = 0"
              >{{ $t("label.projectManagement.bar.chart") }}</el-button
            >
            <el-button
              :type="item.switchFlg == 1 ? 'primary' : ''"
              size="medium"
              style="border-radius: 0px 3px 3px 0px"
              :disabled="item.disable"
              @click="item.switchFlg = 1"
              >{{ $t("label.ring_chart") }}</el-button
            >
          </div>
        </div>
        <table-panel
          v-if="item.switchFlg == 0"
          ref="tablePanel"
          :customHeader="true"
          :dataList="item.codevalues"
          :tableAttr="tableAttr"
        ></table-panel>
        <div
          class="pieBox"
          v-if="item.switchFlg == 1 && item.echartdata.length > 0"
        >
          <pie :opinionData="item.echartdata" :echartStyle="piestyle"></pie>
        </div>
        <div
          v-if="item.switchFlg == 1 && item.echartdata.length == 0"
          class="nodata"
        >
          {{ $t("vue_label_SMS_nodata") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tablePanel from '@/views/marketForm/components/TablePanel/index.vue'
import pie from '@/views/marketForm/components/Pieecharts.vue'
import * as marketChannel from "./api";//api

export default {
  name: "aaa",
  components: {
    pie,
    tablePanel,
  },
  props:{
    customcreatedate:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      fields: 0,
      switchFlg: 0,
      // 统计表头
      tableAttr: [
        {
          schemefieldName: this.$i18n.t("label.Choose"),
          schemefieldType: "L",
          key: "leixing",
        },
        {
          schemefieldName: this.$i18n.t("label.answers"),
          schemefieldType: "PE",
          key: "percentage",
        },
      ],
      dataList: [
        { percentage: 0.1, leixing: "广告宣传" },
        { percentage: 0.9, leixing: "广告宣传" },
      ],
      piestyle: { height: "300px" },
      Daterange: this.$i18n.t("label.notification.last30days"),
      Dateranges: [
        this.$i18n.t("component.timeline.label.today"),
        this.$i18n.t("label.brief.thisweek"),
        this.$i18n.t("this.year"),
        this.$i18n.t("label.notification.last30days"),
        this.$i18n.t("label.custom_time"),
      ],
      datepickervalue: [],
      pickerOptions:{
        disabledDate(v){
                return v.getTime() > Date.now();
              }
      },
      zhuzhuanghengchartOptions: {}, //横向柱状图
      startdate: "", //开始时间
      enddate: "", //结束时间
      allpage: null, //数据总条数
      pagesize: 5, //每页条数
      pagecount: 1, //第几页
      required: [], //全部字段
      requireddata: [], //全部字段数据
      norequired: [], //选填字段
      norequireddata: [], //选填字段数据
      tabledata: [],
      echartquired: [""],
    };
  },
  mounted() {},
  watch: {
    datepickervalue() {
      this.startdate = this.datepickervalue[0];
      this.enddate = this.datepickervalue[1];
      this.getFormField();
      this.getFormFieldType();
    },
    // Daterange() {
    //   this.$store.state.creatform.analydaterange = this.Daterange
    // }
  },
  methods: {
    choosefields(num) {
      this.fields = num;
      this.pagedata();
    },
    handleCurrentChange(val) {
      this.pagecount = val;
      this.pagedata();
    },
    swicthIcon(flg) {
      this.switchFlg = flg;
    },
    handleCommand(command) {
      this.Daterange = command;
      this.getformdates(command);
      this.currentPage = 1;
      if (command !== this.$i18n.t("label.custom_time")) {
        this.getFormField();
        this.getFormFieldType();
      } else {
        this.datepickervalue = [this.customcreatedate,new Date().toLocaleDateString().replace(/\//g, "-")]
        let dataVal = new Date(this.customcreatedate)
        this.pickerOptions = {
            disabledDate(v){
              if(v.getTime()< dataVal.getTime()-24*60*60*1000){
                return true
              }
              if(v.getTime() > Date.now()){
                return true
              }
            }
          }
      }
    },
    getformdates(time) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var nowDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      if (time == this.$i18n.t("component.timeline.label.today")) {
        this.startdate = nowDate;
        this.enddate = nowDate;
      }
      let lastDate = "";
      if (time == this.$i18n.t("label.brief.thisweek")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 7);
      }
      if (time == this.$i18n.t("label.notification.last30days")) {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 30);
      }
      if (time == this.$i18n.t("this.year")) {
        this.startdate = year + "-01-01";
        this.enddate = nowDate;
      }
      if (time == this.$i18n.t("label.all_time")) {
        this.startdate = "";
        this.enddate = "";
      }
      if (lastDate) {
        var lastY = lastDate.getFullYear();
        var lastM = lastDate.getMonth() + 1;
        var lastD = lastDate.getDate();
        var lastdate =
          lastY +
          "-" +
          (lastM < 10 ? "0" + lastM : lastM) +
          "-" +
          (lastD < 10 ? "0" + lastD : lastD);
        this.startdate = lastdate;
        this.enddate = nowDate;
      }
    },
    //横向柱状图
    zhuzhuanghengChat(title, num) {
      this.zhuzhuanghengchartOptions = {
        // title:{
        //     text:'Overall responses'
        // },
        // 图例
        legend: {
          data: [this.$i18n.t("label.reg_field")],
          icon: "circle",
          left: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 25,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        grid: {
          // top: "12%",
          left: "0%",
          right: "4%",
          // bottom: "10%",
          containLabel: true,
        },
        yAxis: {
          type: "category",
          nameLocation: "end",
          nameGap: 12,
          nameTextStyle: {
            align: "left",
          },
          // 坐标轴刻度是否朝内
          axisTick: {
            inside: true,
          },
          data: title,
        },
        series: [
          {
            name: this.$i18n.t("label.reg_field"),
            type: "bar",
            center: ["50%", "60%"], //位于图表的位置
            data: num,
            barWidth: 30,
            itemStyle: {
              normal: {
                color: "#FEA58E", //圈圈的颜色
                lineStyle: {
                  color: "#FEA58E", //线的颜色
                },
              },
            },
          },
        ],
      };
    },
    // 横向柱状图数据
    getFormField() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.startdate,
        endTime: this.enddate,
      }
      marketChannel.getFormFieldSubmissions(obj).then((res) => {
        let fieldSub = res.data.fieldSubmissions;
        let reqired = [];
        let requireddata = [];
        let noreqired = [];
        let norequireddata = [];
        for (var i = 0; i < fieldSub.length; i++) {
          requireddata.push(fieldSub[i].count)
          if (fieldSub[i].required == '1') {
            reqired.push('* ' + fieldSub[i].label);

          } else {
            reqired.push(fieldSub[i].label);
            noreqired.push(fieldSub[i].label);
            norequireddata.push(fieldSub[i].count)
          }
          this.required = reqired;
          this.requireddata = requireddata;
          this.norequired = noreqired;
          this.norequireddata = norequireddata;
          this.pagedata();
        }})
        .catch(() => {
          return false;
        });
    },
    //柱状图数据处理
    pagedata() {
      let echartstitle = [];
      let echartsdata = [];
      if (this.fields == 0) {
        this.allpage = this.required.length;
        if (this.allpage > this.pagesize) {
          let start = this.pagesize * (this.pagecount - 1);
          let end = this.pagesize * this.pagecount;
          echartstitle = this.required.slice(start, end);
          echartsdata = this.requireddata.slice(start, end);
        } else {
          echartstitle = this.required;
          echartsdata = this.requireddata;
        }
      } else {
        this.allpage = this.norequired.length;
        if (this.allpage > this.pagesize) {
          let start = this.pagesize * (this.pagecount - 1);
          let end = this.pagesize * this.pagecount;
          echartstitle = this.norequired.slice(start, end);
          echartsdata = this.norequireddata.slice(start, end);
        } else {
          echartstitle = this.norequired;
          echartsdata = this.norequireddata;
        }
      }
      this.echartquired = echartstitle;
      this.zhuzhuanghengChat(echartstitle, echartsdata);
    },
    //获取表格数据
    getFormFieldType() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.startdate,
        endTime: this.enddate,
      };
      marketChannel
        .getFormFieldTypeSubmissions(obj)
        .then((res) => {
          if (res.returnCode == "1") {
            let fieldata = res.data.fields;
            for (let i = 0; i < fieldata.length; i++) {
              let obj = {};
              let datalist = [];
              let echartdata = [];
              let pages = 0;
              obj.label = fieldata[i].label;
              obj.schemefieldName = fieldata[i].schemefieldName;
              obj.id = fieldata[i].id;
              for (let j = 0; j < fieldata[i].codevalues.length; j++) {
                let lists = {};
                let echartlist = {};
                let dataname = fieldata[i].codevalues[j].codevalue;
                lists.leixing = dataname;
                echartlist.name = dataname;
                if (fieldata[i].submissions.length == 0) {
                  lists.percentage = "0";
                  echartlist.value = "0.0";
                  // eslint-disable-next-line no-undef
                  lista.submissionRate = "0%";
                } else {
                  for (let z = 0; z < fieldata[i].submissions.length; z++) {
                    if (
                      fieldata[i].submissions[z].fieldvalue == dataname &&
                      fieldata[i].submissions[z].submission
                    ) {
                      let num =
                        fieldata[i].submissions[z].submission /
                        fieldata[i].submissionCount;
                      lists.submissionRate = num * 100 + "%";
                      lists.percentage = fieldata[i].submissions[z].submission;
                      pages = +num;
                      echartlist.value = fieldata[i].submissions[z].submission;
                      break;
                    } else {
                      lists.submissionRate = "0%";
                      lists.percentage = "0";
                      echartlist.value = "0.0";
                    }
                  }
                }
                datalist.push(lists);
                echartdata.push(echartlist);
              }

              if (pages > 1) {
                obj.disable = true;
              } else {
                obj.disable = false;
              }
              obj.codevalues = datalist;
              obj.switchFlg = 0;
              obj.echartdata = echartdata;
              this.tabledata.push(obj);
            }
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
  created() {
    this.getformdates(this.Daterange);
    this.getFormField();
    this.getFormFieldType();
    // if (this.$store.state.creatform.analydaterange) {
    //   this.Daterange = this.$store.state.creatform.analydaterange
    // }
  },
};
</script>

<style lang="scss" scoped>
.top {
  padding-bottom: 10px;
}
.iconselect {
  margin-bottom: 10px;
}
.chatTitle {
  float: left;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #080707;
  font-weight: bold;
  line-height: 50px;
}
.buttoonWrapper {
  float: right;
  margin-top: 5px;
  .el-button + .el-button {
    margin-left: 0;
  }
}
.pd-20 {
  padding: 0px 20px;
}
.empList {
  width: 98.5%;
  // min-height: 430px;
  margin: 10px auto;
  padding: 20px 0px;
  background-color: #ffffff;
  .empTitle {
    float: left;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #080707;
    font-weight: bold;
    line-height: 50px;
  }
}
.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}
.pieBox {
  background-color: #ffffff;
  width: 98.5%;
  margin: 10px auto;
  position: relative;
}
.emailIcon {
  margin: 0px 13px 10px 10px;
}
.clear {
  clear: both;
}
.countyBox {
  width: 98.5%;
  margin: 0px 10px 10px 10px;
}
.comNameBox {
  float: left;
  width: 49%;
  margin: 0px 0px 10px 10px;
}
.phoneBox {
  float: right;
  width: 49%;
  margin: 0px 10px 0px 10px;
}
.emailBox {
  float: left;
  width: 49%;
  margin: 10px 0px 10px 10px;
}
.fullNameBox {
  float: right;
  width: 49%;
  margin: 10px 10px 0px 10px;
}
.datepicker {
  margin-left: 15px;
  ::v-deep {
    .el-range-editor.el-input__inner {
      padding: 0;
      cursor: pointer;
      width: 220px;
    }
    .el-input__inner {
      border: 0;
      height: 20px;
      line-height: 20px;
    }
    .el-range-input {
      cursor: pointer;
      color: #2D6CFC;
    }
    .el-date-editor .el-range__icon {
      line-height: 20px;
      margin: 0;
      width: 20px;
    }
    .el-date-editor .el-range-separator {
      line-height: 20px;
      padding: 0;
    }
    .el-date-editor .el-range-input {
      width: 90px;
    }
    .el-date-editor .el-range__close-icon {
      line-height: 22px;
      width: 20px;
    }
  }
}
.Daterange {
  float: left;
  margin-left: 16px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  width: 250px;
  .el-dropdown-link {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #2D6CFC;
    letter-spacing: 0;
  }
}
.analyzeBox {
  width: 98.5%;
  margin: auto;
  .tapHeader {
    width: 100%;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    letter-spacing: 0;
    margin-top: 10px;
  }
  .myChart {
    width: 100%;
    height: 470px;
    background: #ffffff;
    padding: 20px;

    .vChart {
      width: 100%;
      height: 350px;
    }
    .echartnodata {
      width: 100%;
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.chartHeader {
  width: 100%;
  height: 50px;
  .chatTitle {
    float: left;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #080707;
    font-weight: bold;
    line-height: 50px;
  }
  .buttoonWrapper {
    float: right;
    margin-top: 5px;
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>