import axios from '@/config/httpConfig'
// 获取form的基本信息
export function getFormInfo(data) {
    return axios.post('/form/getFormInfo', data)
}
//获取form的字段提交信息
export function getFormFieldSubmissions(data) {
    return axios.post('/form/getFormFieldSubmissions', data)
}
//获取form的选项列表字段提交信息
export function getFormFieldTypeSubmissions(data) {
    return axios.post('/form/getFormFieldTypeSubmissions', data)
}