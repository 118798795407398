<template>
  <div>
    <div ref="piechart" :style="echartStyle" class="pieechart"></div>
  </div>
</template>
<script >
let echarts = require('echarts')
export default {
  name: 'pie',
  props: {
    echartStyle: {  // 样式 - 1
      type: Object,
      default() {
      }
    },
    titleText: {   //标题文本 - 1
      type: String,
      default: ''
    },

    // subtext: {    //区域名称 - 1
    //   type: String,
    //   default: ''
    // },

    opinionData: {   // 区域数据 - 1
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine();
    })
  },
  methods: {
    drawLine() {
      let pieCharts = echarts.init(this.$refs.piechart)
      pieCharts.setOption({
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          icon: 'circle',
          top: "0%",
          left: 'left',
        },
        series: [
          {
            name: '',
            type: 'pie',
            center: ["50%", "50%"],
            radius: ['30%', '70%'],
            itemStyle: {
              borderWidth: 1, //设置border的宽度有多大
              borderColor: '#FFFFFF',
            },
            data: this.opinionData,
          }
        ] // 区域数据
      })
      window.addEventListener('resize', this.windowResizeFn)
    },
    windowResizeFn(pieCharts) {
      pieCharts.resize();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeFn);
  },
}

</script>
<style lang='scss' scoped>
.pieechart {
  width: 99%;
}
</style>
