<template>
  <div class="formdetail">
    <div class="mainheader">
      <!-- 头部 -->
      <div class="header">
        <div class="hupleft" @click="goBack">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-back"></use>
          </svg>
          {{ $t("label.back_forms") }}
        </div>
        <div class="hupright">
          <!-- <el-select v-model="aselect" placeholder="Actions">
            <el-option v-for="item in options" :key="item" :label="item">
            </el-option>
          </el-select> -->
          <el-dropdown :placement="bottom - start" @command="handleCommand">
            <span
              class="el-dropdown-link"
              style="
                border: 1px solid #dedcda;
                padding: 4px 6px 6px 12px;
                border-radius: 3px;
                color: #2D6CFC;
              "
            >
              {{ $t("label.quickbooks.button.actions")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in options"
                :key="item"
                :command="item"
                 class="form_drop"
                style="font-size:12px;height:30px;line-height:30px;"
                >{{ item }}<svg
                  class="icon"
                  aria-hidden="true"
                  v-if="item == `${$t('label.create_contacts')}`"
                >
                  <use href="#icon-share"></use></svg
                >                     
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button @click="ExportData = true">{{
            $t("label.reportexport")
          }}</el-button>
          <el-button @click="editclick">{{
            $t("label.emailtocloudcc.edit")
          }}</el-button>
        </div>
      </div>
      <div class="intro">
        <span>{{ formbasics.subject }}</span>
        <span
          >（{{ formbasics.submissions }}
          {{ $t("vue_label_normal_contact_sf") }}）</span
        >
      </div>
      <div class="conmain">
        <div class="conl" @click="getshareFun">
          <div class="small_img">
            <div class="small_form">
              <div
                v-for="(formlis, formlisid) in formbasics.formField"
                :key="formlisid"
                class="small_form_one"
              >
               <!-- :class="
                  formbasics.formField.length % 2 == 0 &&
                  (formlisid == 0 ||
                    formlisid == formbasics.formField.length - 1)
                    ? 'small_form_one'
                    : formbasics.formField.length % 2 == 1 && formlisid == 0
                    ? 'small_form_one'
                    : 'small_form_two'
                " -->
                <p>
                  <span v-if="formlis.required == '1'">*</span
                  >{{ formlis.label }}
                </p>
                <input type="text" />
              </div>
              <div class="subprim">
                <button>{{ $t("label.submit") }}</button>
              </div>
            </div>
          </div>
          <p class="viewform">
            {{ $t("label.view_form") }}<svg class="icon" aria-hidden="true">
              <use href="#icon-sharewhite"></use>
            </svg>
          </p>
        </div>
        <div class="conr">
          <p>{{ $t("homepage.components.creationtime") }}</p>
          <p>{{ formbasics.createdate }}</p>
          <p v-if="formbasics.lastsubmitdate">{{ $t("label.last_submission") }}</p>
          <p v-if="formbasics.lastsubmitdate">{{ formbasics.lastsubmitdate}}</p>
          <!-- <p>Set contacts as marketing</p>
          <p>{{ contacts_as_marketing }}</p> -->
        </div>
      </div>
      <!-- 表单URL窗口确认框 -->
      <el-dialog
        :title="$t('label.view_form')"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <p>{{ $t("label.test_visitors") }}</p>
        <!-- <p>
          {{ $t("label.mirror_issues") }}
          <span style="color: #2D6CFC"
            >{{ $t("page.404.button.toknowmore") }}.</span
          >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-share"></use></svg
          >
        </p> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{
            $t("label.tabpage.closez")
          }}</el-button>
          <el-button type="primary" @click="getshareFun"
            >{{ $t("label.view_form") }}
            <svg class="icon" aria-hidden="true">
              <use href="#icon-sharewhite"></use></svg
          ></el-button>
        </span>
      </el-dialog>
      <!-- tab菜单 -->
      <div class="dtabs">
        <el-tabs v-model="activeName" @tab-click="handleClick" stretch="true">
          <el-tab-pane
            :label="$t('label.mobile.phone.results')"
            name="first"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('vue_label_systemSettings_analyse')"
            name="second"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label.submissions')"
            name="third"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <performance v-if="perFlg" :formid="formid" :customcreatedate="customcreatedate"></performance>
    <analyze v-if="anaFlg" :customcreatedate="customcreatedate"></analyze>
    <submission v-if="subFlg" :customcreatedate="customcreatedate"/>
    <!-- 导出form -->
    <el-dialog
      :title="$t('label.export_submissions')"
      :visible.sync="ExportData"
      width="587px"
      custom-class="dialog"
    >
      <div class="dialogdiv">
        <p>{{ $t("label.file_format") }}</p>
        <el-select v-model="ExportDatavalue">
          <el-option
            v-for="item in ExportDataoptions"
            :key="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <!-- <p>{{ $t("label.exported_to") }}</p>
        <p>qimh@cloudcc.com {{ $t("label.posted_your") }}</p>
        <p>{{ $t("label.notifications_center") }}</p>
        <div>
          <p>{{ $t("label.qetting_notifications") }}</p>
          <b>{{ $t("label.notifications") }}</b>
        </div> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ExportData = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="exporedata">{{
          $t("label.reportexport")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 克隆form -->
    <el-dialog
      :title="$t('label.clone_form')"
      :visible.sync="Cloneform"
      width="587px"
      custom-class="dialog"
    >
      <div class="dialogdiv">
        <div>
          <p>
            {{ $t("label.name_copy") }}
          </p>
        </div>
        <p>{{ $t("label.new_formname") }}</p>
        <input type="text" v-model="Cloneformvalue" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Cloneform = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="formclone">{{
          $t("label.clone")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- delete弹框 -->
    <el-dialog
      :title="$t('label.emailobject.delete') + deletetitle + ' ?'"
      :visible.sync="deleteshow"
      width="587px"
      top="20vh"
    >
      <div class="deletecontent">
        <p>
          {{ $t("label.delete_about") }} '{{ deletetitle }}'.
          {{ $t("label.undone_external page") }}
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteshow = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="deletefrom">{{
          $t("pagecreator.page.button.delete")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 分享 -->
    <share ref="share" :checkedid="Cloneformid"></share>
  </div>
</template>

<script>

import * as marketChannel from "./api";//api
import axios from "@/config/httpConfig";
import submission from "@/views/marketForm/detailForm/component/Submissions/index.vue";
import analyze from "@/views/marketForm/detailForm/component/analyze/index.vue";
import performance from "@/views/marketForm/detailForm/component/Performance/index.vue";
import share from "@/views/marketForm/components/share.vue";

export default {
  data() {
    return {
      contacts_as_marketing: "Yes",
      subFlg: false,
      anaFlg: false,
      perFlg: true,
      svalue: "",
      activeName: "first",
      aselect: "",
      options: [
        this.$i18n.t("label.emailtocloudcc.button.view"),
        this.$i18n.t("label.clone"),
        this.$i18n.t("pagecreator.page.button.delete"),
        this.$i18n.t("label.file.share")
      ],
      formid: '',//传进 form 的id
      dialogVisible: false,
      formbasics: {},
      shareurl: '',//分享链接
      ExportData: false,//导出控制
      ExportDatavalue: "xls",//导出文件类型
      ExportDataoptions: ["xls", "xlsx"],
      Cloneform: false,
      Cloneformvalue: "",
      Cloneformid: '',//克隆id
      deletetitle:'',
      deleteshow:false,
      customcreatedate:""
    };
  },
  components: {
    submission,
    analyze,
    performance,
    share
  },
  methods: {
    goBack() {
      this.$store.state.creatform.itemDataId = '';
      this.$router.go(-1);
    },
    handleClick(tab) {
      if (tab.index === "0") {
        this.perFlg = true;
        this.subFlg = false;
        this.anaFlg = false;
      } else if (tab.index === "1") {
        this.perFlg = false;
        this.subFlg = false;
        this.anaFlg = true;
      } else {
        this.perFlg = false;
        this.subFlg = true;
        this.anaFlg = false;
      }
    },
    handleCommand(command) {
      //复制
      if(command == this.$i18n.t("label.clone")){
        this.cloneformevent()
      }
      //删除
      if(command == this.$i18n.t("pagecreator.page.button.delete")){
        this.deleteshow = true;
        this.deletetitle = this.formbasics.subject
      }
      //分享
      if(command == this.$i18n.t("label.file.share")){
        this.getshare()
      }
      if (command == this.$i18n.t("label.emailtocloudcc.button.view")) {
        this.getshareFun();
      }
      if (command == this.$i18n.t("label.create_contacts")) {
        this.$router.push({
          name: 'particulars',
          params: {
            id: this.formbasics.subject,
            title: 'Active list',
            acsta: true,
          }
        })
      }
    },
    //获取form的分享信息
    getshare() {
      this.Cloneformid = this.$store.state.creatform.itemDataId;
      this.$refs.share.shareDialog = true
      this.$refs.share.init()
    },
    //删除单条数据
    deletefrom() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
      };
      marketChannel.deleteForm(obj).then((res) => {
        if (res.returnCode == '1') {
          this.$message({
            message: this.$i18n.t('label.tabpage.delsuccessz'),
            type: 'success'
          });
          this.deleteshow = false;
          this.$router.go(-1)
        } else {
          this.$message.error(this.$i18n.t('label.ems.delete.false'));
          this.deleteshow = false;
        }
      })
    },
    //克隆编辑时间格式
    cloneformevent() {
      this.Cloneform = true;
      let newdate = new Date();
      let Y = newdate.getFullYear();//年
      var M = newdate.getMonth();//月
      let D = newdate.getDate();//日
      let H = newdate.getHours();//时
      let m = newdate.getMinutes(); //分
      let S = newdate.getSeconds();//秒
      let monlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      let Mon = monlist[M];
      let Mdate;
      if (H >= 12) {
        Mdate = Mon + '  ' + D + " , " + Y + "  " + (H - 12) + ":" + m + ":" + S + '  PM '
      } else {
        // eslint-disable-next-line no-unused-vars
        Mdate = Mon + '  ' + D + " , " + Y + "  " + H + ":" + m + ":" + S + ' AM '
      }
      this.Cloneformvalue = this.formbasics.subject + "( " + this.$i18n.t('label.clone') + " )";
      this.Cloneformid = this.$store.state.creatform.itemDataId;
    },
    //获取隐藏字段
    getMember(params) {
      return new Promise(function (resolve, reject) {
        marketChannel.getFieldDefault(params).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err);
        })
      });
    },
    //获取注册页面
    getSource(params) {
      return new Promise(function (resolve, reject) {
        marketChannel.getFieldShow(params).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err);
        })
      });
    },
    //获取详细
    getDite(params) {
      return new Promise(function (resolve, reject) {
        marketChannel.getFormdetail(params).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err);
        })
      });
    },
    //克隆
    formclone() {
      let params = {
        id: this.Cloneformid,
      }
      let cloneda = {};
      Promise.all([this.getMember(params), this.getSource(params), this.getDite(params)]).then((res) => {
        res[1].data.selectedFields.map((item)=>{
          item.required=item.required?item.required:"0"
        })
        cloneda.leadDefaultValues = JSON.stringify(res[0].data.leaddvalue);
        cloneda.fields = JSON.stringify(res[1].data.selectedFields);
        cloneda.channel = this.Cloneformvalue;
        cloneda.subject = this.Cloneformvalue;
        cloneda.remark = res[2].data.remark;
        cloneda.logo = res[2].data.logo;
        cloneda.bgimage = res[2].data.bgimage;
        cloneda.modelType = res[2].data.modeltype;
        cloneda.rtnType = res[2].data.rtntype;
        cloneda.rtnUrl = res[2].data.rtnurl;
        cloneda.campaignId = res[2].data.campaignid;
        cloneda.userEmailIds = res[2].data.userEmailIds;
        cloneda.emails = JSON.stringify(res[2].data.emailList);
        if (res[2].data.longitude) {
          cloneda.longitude = res[2].data.longitude;
        } else {
          cloneda.longitude = '';
        }
        if (res[2].data.latitude) {
          cloneda.latitude = res[2].data.latitude;
        } else {
          cloneda.latitude = '';
        }
        if (res[2].data.address) {
          cloneda.address = res[2].data.address;
        } else {
          cloneda.address = '';
        }
        cloneda.operation = 'COPY';
        this.clonesave(cloneda);
      }).catch(() => {
        return false
      })
    },
    clonesave(cloneda) {
      this.Cloneform = false;
      marketChannel.saveForm(cloneda).then((res) => {
        if (res.returnCode == "1") {

          this.$message.success(this.$i18n.t("label.copy.success"))
          this.$router.go(-1)
        } else {
          this.$message.error(this.$i18n.t("label.copy_error"));
        }
      })
    },
    //获取基本信息
    getform() {
      let obj = {
        id: this.formid,
      };
      marketChannel.getFormInfo(obj).then((res) => {
        if (res.returnCode == '1') {
          this.formbasics = res.data;
          this.shareurl = res.data.url;
          this.customcreatedate = res.data.customcreatedate
        }
      })
    },
    //获取form的分享信息
    getshareFun() {
      window.open(this.shareurl, '_blank');
    },
    //编辑
    editclick() {
      this.$store.state.creatform.actionType = 'edit'
      this.$router.push({
        name: 'createFrom',
        params: {
          formname: this.formbasics.subject,
          tap: { index: '0' },
        }
      })
    },
    //导出
    exporedata() {
      let obj = {
        id: this.formid,
        exportType: this.ExportDatavalue,
      }
      // let app = JSON.stringify(obj)
      axios.post(`form/exportFormInfo`, obj, {
        // responseType: "arraybuffer",
        responseType: 'blob',
      }).then((res) => {
        let blob = new Blob([res],); //type这里表示xlsx类型
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = 'exportform.' + this.ExportDatavalue; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象 
      })
    }
  },
  created() {
    this.formid = this.$store.state.creatform.itemDataId;
    if (this.$route.params.id) {
      if (this.$route.params.id === "0") {
        this.perFlg = true;
        this.subFlg = false;
        this.anaFlg = false;
        this.activeName = "first";
      } else if (this.$route.params.id === "1") {
        this.perFlg = false;
        this.subFlg = false;
        this.anaFlg = true;
        this.activeName = "second";
      } else {
        this.perFlg = false;
        this.subFlg = true;
        this.anaFlg = false;
        this.activeName = "third";
      }
    }
    this.getform();
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-table td,
// .building-top .el-table th.is-leaf {
//   // color: red;
// }
.icon {
  display: inline-block;
  margin-left: 4px;
}
::v-deep :focus {
  outline: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0px !important;
}
::v-deep .el-tabs--top {
  height: 40px;
}
::v-deep .is-top {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
  background-color: #fff;
}
::v-deep .el-tabs__item.is-active{
  color: #2D6CFC;
}
::v-deep .el-tabs__item:hover{
  color: #2D6CFC;
}
::v-deep .el-tabs__active-bar {
  background-color: #2D6CFC;
}
::v-deep .is-active {
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
}
::v-deep .el-input__icon {
  line-height: 0;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 103px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #2D6CFC;
  letter-spacing: 0;
  text-align: center;
}
.formdetail {
  height: 800px;
  .mainheader {
    width: 98.5%;
    margin: 10px auto 0px;
    padding: 20px 20px 0 20px;
    background-color: #fff;
    border: 1px solid #dddbda;
    border-radius: 3px;
    .header {
      height: 441px;
      //   background-color: #fff;
      width: 98.5%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      .hupleft {
        height: 22px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #2D6CFC;
        letter-spacing: 0;
        cursor: pointer;
        img {
          height: 15.6px;
          width: 15.6px;
        }
      }
      .hupright {
        height: 50px;
        width: 300px;
        margin-right: -55px;
        .el-dropdown{
          span{
            font-size:12px;
          }
        }
        button {
          height: 30px;
          border: 1px solid #dedcda;
          border-radius: 3px;
          padding-top: 8px;
        }

        button:nth-child(2) {
          width: 77px;
          margin-left: 10px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #2D6CFC;
          letter-spacing: 0;
          text-align: center;
        }
        button:nth-child(3) {
          background: #2D6CFC;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
        }
      }
    }
    .intro {
      span:nth-child(1) {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #080707;
        letter-spacing: 0;
      }
      span:nth-child(2) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #888888;
        letter-spacing: 0;
      }
    }
    .conmain {
      display: flex;
      .conl {
        width: 289px;
        height: 244px;
        background: #f0f0f0;
        border: 1px solid #dedcda;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        .viewform {
          position: absolute;
          top: 100px;
          left: 95px;
          display: none;
          font-size: 14px;
          color: #fff;
        }
      }

      .conl:hover {
        transition: background-color 0.3s;
        background-color: rgba(81, 111, 144, 0.85);
      }
      .conl:hover .small_img {
        opacity: 0.05;
      }
      .small_img {
        width: 100%;
        height: 100%;
      }
      .small_form {
        width: 96%;
        height: 96%;
        margin-top: 2%;
        margin-left: 2%;
        background: #fff;
        overflow: hidden;
        p {
          font-size: 12px;
          transform: scale(0.9);
          transform-origin: left;
          margin: 0;
        }
        .small_form_one {
          width: 94%;
          margin-left: 3%;
          margin-top: 10px;
          p {
            width: 100%;
            span {
              color: red;
              padding-right: 4px;
            }
          }
          input {
            width: 100%;
            border: 1px solid #ddd;
            outline: none;
          }
        }
        .small_form_two {
          width: 45%;
          margin-left: 3%;
          float: left;
          margin-top: 2%;
          p {
            width: 100%;
            span {
              color: red;
              padding-right: 4px;
            }
          }
          input {
            width: 100%;
            border: 1px solid #ddd;
            outline: none;
          }
        }
        .subprim {
          width: 94%;
          margin-left: 3%;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          button {
            width: 50px;
            margin-top: 10px;
            font-size: 12px;
            transform: scale(0.9);
            background: #2D6CFC;
            outline: none;
            text-align: center;
            line-height: 20px;
            border: none;
            color: #fff;
            border-radius: 3px;
          }
        }
      }
      .conl:hover .viewform {
        display: block;
      }
      .conr {
        margin-left: 40px;
        p:nth-child(odd) {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #888888;
          letter-spacing: 0;
          // margin-bottom: 5px;
          margin:5px 0;
        }
        p:nth-child(even) {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0;
          margin:5px 0;
        }
      }
    }
    .dtabs {
      width: 30%;
      margin-top: 40px;
    }
  }
}
::v-deep .dialog {
  .dialogdiv {
    width: 100%;
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      text-align: left;
      color: #080707;
    }
    input {
      width: 100%;
      border: 1px solid #dedcda;
      border-radius: 3px;
      height: 40px;
    }
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
    div {
      margin-bottom: 20px;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
      }
      b {
        color: #2D6CFC;
      }
    }
  }
}
::v-deep .el-dialog__headerbtn {
  top: auto !important;
}
::v-deep .el-dialog__header {
  display: flex;
  align-items: center;
}
</style>
<style scoped lang="scss">
.el-popper {
  ::v-deep li {
    &:hover {
      color: #2D6CFC;
      cursor: pointer;
      background: #f3f2f2;
    }
  }
}
</style>
